import { template as template_b9095f05b30c43cba7ca27d8a2e59ab0 } from "@ember/template-compiler";
const FKText = template_b9095f05b30c43cba7ca27d8a2e59ab0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
